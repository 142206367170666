<template>
  <div class="qrcode">
    <div class="qrcode-opr">
      <div class="qrcode-opr-btn">
        <p class="title">取货码</p>
        <p class="remark">吸引增长新客户，福利定向老客户</p>
        <el-button @click="handleCreateQrcode" type="primary" size="small">立即创建</el-button>
      </div>

      <div class="qrcode-opr-btn">
        <p class="title">期待更多</p>
        <p class="remark">我们正在募集更多新玩法，期待您的加入</p>
      </div>
    </div>

    <el-table
      class="qrcode-list"
      :data="qrcodes"
      style="width: 100%">
      <empty slot="empty"></empty>
      <el-table-column
        prop="name"
        label="名称"
        width="240">
      </el-table-column>

      <el-table-column
        align="center"
        width="120"
        label="发送总量/剩余总量">
        <template slot-scope="scope">
          {{scope.row.total}}/{{scope.row.total-scope.row.used}}
        </template>
      </el-table-column>

      <el-table-column
        prop="used"
        label="已使用"
        align="center"
        width="120">
      </el-table-column>

      <el-table-column
        prop="drinkName"
        align="center"
        label="适用饮品">

      </el-table-column>

      <el-table-column
        prop="deviceName"
        align="center"
        label="适用设备">
      </el-table-column>

      <el-table-column
        prop="date"
        width="80"
        align="center"
        label="状态">
        <template slot-scope="scope">
          {{scope.row.startAt | filterStatus(scope.row.endAt)}}
        </template>
      </el-table-column>

      <el-table-column
        prop="date"
        align="center"
        label="活动时间">
        <template slot-scope="scope">
          <span v-if="(new Date(scope.row.endAt * 1000).getFullYear() - new Date().getFullYear()) < 50">
            {{new Date(scope.row.startAt * 1000).Format('yyyy-MM-dd')}}/
          {{new Date(scope.row.endAt * 1000).Format('yyyy-MM-dd')}}
          </span>
          <span v-else>{{new Date(scope.row.startAt * 1000).Format('yyyy-MM-dd')}}-长期有效</span>
        </template>
      </el-table-column>

      <el-table-column
        fixed="right"
        label="操作"
        align="center">
        <template slot-scope="scope">
          <el-link :underline="false" class="bold" @click="handleManagerQrcode(scope.row)" type="primary" size="small">
            条码清单
          </el-link>
          <el-link :underline="false" @click="handleEdit(scope.row.uuid)" type="primary" size="small">调整</el-link>
          <el-link :underline="false" @click="handleExport(scope.row)" type="primary" size="small">下载</el-link>
          <el-link :underline="false" @click="handleDelete(scope.row.uuid, scope.row.$index)"
                   type="danger" size="small">删除
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>

    <c-dialog :visible.sync="downloadVisible" :outerClose="false" class="qr-dialog" :title="'下载清单'" :height="'10rem'">
      <div class="qr-dialog-margin">
        <el-progress :text-inside="true" :stroke-width="18" :percentage="percentage"></el-progress>
        <span>{{text}}</span>
      </div>
    </c-dialog>

  </div>
</template>

<script>
  import {qrcodeUnion, qrCodeList, qrCodeDelete, qrcodeItemCnt, qrCodeItemList} from '@/api/marketing/qrcode'
  import Excel from "@/util/excel.js";
  import CDialog from '@/components/Dialog'
  import {mapGetters} from "vuex";

  export default {
    name: '',
    components: {
      CDialog
    },
    computed: {
      ...mapGetters(['employee'])
    },
    activated: function () {
      this.loadQrCode()
    },
    filters: {
      filterStatus(startTs, endTs) {
        let t = new Date().getTime() / 1000
        if (startTs > t) {
          return '暂未开始'
        }

        if (endTs < t) {
          return '已结束'
        }

        return '进行中'
      },
    },
    watch: {
      'percentage': function (val) {
        if (val === 100) {
          this.text = '正在生成数据，请耐心等待...'
          Excel.exportExcel(this.data, this.qrCode.name + '清单表')
          this.downloadVisible = false
        }
      }
    },
    data: function () {
      return {
        downloadVisible: false,
        pagination: {
          name: '',
          province: '',
          city: '',
          curPage: 1,
          limit: 10,
          total: 0,
          orderType: 0,
        },
        percentage: 0,
        text: '正在获取数据，请耐心等待...',
        qrcodes: [],
        data: [],
        qrCode: {
          name: '',
        },
      }
    },
    methods: {
      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        qrCodeList(this.pagination).then(res => {
          this.qrcodes = res.data || []
        })
      },
      loadQrCode() {
        qrcodeUnion(this.pagination).then(res => {
          this.qrcodes = res.data.list || []
          this.pagination.total = parseInt(res.data.total)
        })
      },
      handleCreateQrcode() {
        this.$router.push({name: 'marketing.qrcode.create'})
      },
      handleManagerQrcode(item) {
        this.$router.push({name: 'marketing.qrcode.items', params: item})
      },
      handleEdit(uuid) {
        this.$router.push({name: 'marketing.qrcode.edit', params: {uuid: uuid}})
      },

      handleExport(row) {
        let vue = this
        vue.qrCode = row
        qrcodeItemCnt({uuid: row.uuid, status: 1}).then(res => {
          vue.percentage = 0
          vue.text = '正在获取数据，请耐心等待...'
          vue.downloadVisible = true
          vue.data = new Array()
          vue.downloadData(row, 0, parseInt(res.data, 10))
        })
        // 下载文件

        // 导出文件
      },

      downloadData(row, idx, total) {
        if (total === 0) {
          total = 1
        }

        let vue = this
        qrCodeItemList({uuid: row.uuid, status: 1, start: idx, limit: 50}).then(res => {
          let rsData = res.data || []
          rsData.forEach(rs => {
            vue.data = vue.data.concat({sn: `http://m.yunka-it.cn/pay/#/qr?bid=${vue.employee.enterpriseUuid}&sn=` + rs.sn})
          })
          idx += rsData.length
          this.percentage = Math.floor(idx * 100 / total)

          if (idx < total) {
            this.downloadData(row, idx, total)
          }
        })
      },

      handleDelete(uuid, idx) {
        // qrCodeDelete
        this.$confirm(`是否删除取货码`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          qrCodeDelete({uuid: uuid}).then(res => {
            this.qrcodes.splice(idx, 1)
          })
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  body.light {
    .qrcode {
      &-opr {
        display: flex;

        &-btn {
          width: 33%;
          margin-right: $padding-contain;
          background-color: #b9d7ff66;
          border-radius: $border-radius;
          height: 140px;
          text-align: center;

          .title {
            padding: $padding 0;
            font-size: 16px;
            color: #333333;
          }

          .remark {
            padding-bottom: $padding-8;
            font-size: 13px;
            color: #666666;
          }
        }

      }

      &-list {
        margin-top: $padding;
      }

      .el-link {
        margin-right: $padding-8;
        margin-left: $padding-8;
        font-size: 13px;
        font-weight: normal;
      }

      .bold {
        font-weight: 500;
      }

      .el-link:hover, .el-link:focus {
        font-weight: 500;
      }

      .el-link--primary:hover {
        color: $_theme_color;
      }
    }

    .qr-dialog-margin {
      margin-top: $margin;
      text-align: center;

      span {
        display: inline-block;
        margin: $padding;
      }
    }
  }


</style>
